import "antd/dist/antd.min.css";
import "./styles/globals.sass";
import AppWrapper from "./AppWapper";

import { LiffProvider } from "../src/context/Liff";

import { useAppStateStore } from "./store/appState";

function App() {
  const { APP } = useAppStateStore();
  const { currentConfig } = APP;

  // const LIFFID = '1657493973-LJjPDXJJ'; // 22.12.23 by Stone dev
  const LIFFID = currentConfig.REACT_APP_LIFFID;

  return (
    <LiffProvider liffId={LIFFID}>
      <AppWrapper />
    </LiffProvider>
  );
}

export default App;
